.form-group {

    .field-validation-error {
        border-radius: 7px;
        background: #d45a60 url("./../svg/icon-error.svg") 9px center no-repeat;
        background-size: 21px;
        color: #fff;
        padding: 5px 11px 5px 38px;
    }

    .form-group__field-validation-error {
        margin: 10px 0 3px 0;
        display: table;
    }
}

.radio {
    margin: 0;

    + .radio {
        margin-top: 0;
    }

    label {
        margin: 0;
        font-weight: normal;
        padding-left: 35px;
        color: #4c372e;
        position: relative;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 22px;
            height: 22px;
            background: url("./../svg/icon-radio-unchecked.svg") center no-repeat;
            left: 0;
            top: 0;
            margin-right: 10px;
        }
    }

    input[type='radio'] {
        opacity: 0;

        &:checked {
            + label {

                &::before {
                    background: url("./../svg/icon-radio-checked.svg") center no-repeat;
                }
            }
        }
    }
}

.checkbox {
    margin: 0;

    + .checkbox {
        margin-top: 0;
    }

    label {
        margin: 0;
        font-weight: normal;
        padding-left: 35px;
        color: #4c372e;
        position: relative;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 22px;
            height: 22px;
            background: url("./../svg/icon-checkbox-unchecked.svg") center no-repeat;
            left: 0;
            top: 0;
            margin-right: 10px;
        }
    }

    input[type='checkbox'] {
        opacity: 0;

        &:checked {
            + label {

                &::before {
                    background: url("./../svg/icon-checkbox-checked.svg") center no-repeat;
                }
            }
        }
    }
}

.smart-captcha {
    transform: scale(0.87);
    transform-origin: left;

    @include media-breakpoint-up(sm) {
        transform: none;
        transform-origin: unset;
    }
}
