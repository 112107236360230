$enable-smooth-scroll: false;
/* @import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins"; */
@import "./_functions";
@import "./_variables";
@import "./_mixins";

* {
    box-sizing: border-box;
}

:root {
    --swiper-pagination-color: #3f7f72;
    --swiper-pagination-bullet-inactive-color: #fff;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 3px;
    --swiper-pagination-bullet-size: 12px;
}

.page {
    background: #f9f5ef;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.left-col {

    @include media-breakpoint-up(md) {
        float: left;
        width: 230px;
        margin-right: 30px;
    }

    @include media-breakpoint-up(xxl) {
        width: 290px;
        margin-right: 40px;
    }
}

.right-col {
    padding-bottom: 50px;

    @include media-breakpoint-up(md) {
        float: left;
        width: 460px;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 30px;
        width: 620px;
    }

    @include media-breakpoint-up(xl) {
        width: 880px;
    }

    @include media-breakpoint-up(xxl) {
        width: 950px;
    }
}

.left-mbt {
    
    @include media-breakpoint-up(md) {
        width: 460px;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 30px;
        width: 620px;
    }

    @include media-breakpoint-up(xl) {
        float: left;
        width: 600px;
        margin-right: 30px;
    }

    @include media-breakpoint-up(xxl) {
        width: 620px;
        margin-right: 40px;
    }
}

.right-mbt {
    display: none;

    @include media-breakpoint-up(xl) {
        display: block;
        float: left;
        width: 220px;
    }

    @include media-breakpoint-up(xxl) {
        width: 290px;
    }
}

.brand-menu-wrap {
    clear: both;
    min-height: 60px;
    background: #ffffff url("./../images/brand-bg.png") center top no-repeat;
}

.brand-menu {
    list-style: none;
	padding: 0 15px;
	margin: 0;
	float: left;
    line-height: 1.1;
    column-count: 3;

    @include media-breakpoint-up(md) {
        column-count: 4;
    }
}

.social-chat {
    margin: -15px auto 10px auto;
}

.products-sliders {
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
        margin-bottom: 50px;
    }
}

.products-sliders-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    flex-direction: column;
    
    @include media-breakpoint-up(md) {
        margin-bottom: 30px;
        flex-direction: row;
    }
}

.products-sliders-content {
    background: #fefefd;
    box-shadow: 0px 17px 18px 0 rgba(0, 0, 0, 0.05);
}

.products-sliders__products-sliders-content {
    margin: 0 -15px;

    @include media-breakpoint-up(md) {
        margin: 0 3px 0 0;
    }
}

.product-slider {
    background-color: #d1d1d1;

    .product-slider__slide {
        width: 60%;
        background-color: #fff;

        @include media-breakpoint-up(sm) {
            width: 40%;
        }

        .product-slider__slide-content {
            box-sizing: border-box;
            margin: 0;
            border: #fefefd solid 4px;
            padding: 17px 7px;
            text-align: center;
            min-height: 360px;
            position: relative;

            &:hover {
                border: #e3d79e solid 4px;
            }
        }
    }
}

.product-slider-navigation {
    display: flex;
    align-items: flex-start;
    text-align: center;
    border-top: #d1d1d1 solid 1px;

    .product-slider-navigation__btn {
        display: block;
        height: 45px;

        &:hover {
            background-color: #3f7f72;
        }
    }

    .product-slider-navigation__btn_prev {
        background: url("./../svg/icon-left-arrow-black.svg") center no-repeat;
        flex: 0 0 45px;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;

        &:hover {
            background-image: url("./../svg/icon-left-arrow-white.svg");
            opacity: 0.3;
        }

        @include media-breakpoint-up(sm) {
            flex: 0 0 90px;
        }
    }

    .product-slider-navigation__btn_next {
        background: url("./../svg/icon-right-arrow-black.svg") center no-repeat;
        flex: 0 0 45px;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;

        &:hover {
            background-image: url("./../svg/icon-right-arrow-white.svg");
            opacity: 0.3;
        }

        @include media-breakpoint-up(sm) {
            flex: 0 0 90px;
        }
    }

    .product-slider-navigation__btn_all {
        flex: 1;
        font-family: "Roboto-Light", sans-serif;
        color: #333333;
        border-left: #d1d1d1 solid 1px;
        border-right: #d1d1d1 solid 1px;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 11px;
        line-height: 45px;
        letter-spacing: 1px;

        &:hover {
            color: #fff;
        }
    }
}

.tabs {
    list-style: none;
    margin: 0;
    padding: 0;

    &::before,
    &::after {
        display: table;
        content: " ";
    }

    &::after {
        clear: both;
    }
}

.tabs__nav-item {
    float: left;

    /* compatibility with bootstrap 3 */
    &.active {

        .tabs__nav-link {
            background: #3f7f72;
            border-bottom: #3f7f72 dotted 1px;
            border-radius: 15px;
            color: #fff;
            padding: 5px 25px;
            margin: 0;
            transition: all ease-out 0.2s;

        }
    }
    /* /compatibility with bootstrap 3 */
}

.tabs__nav-link {
    &,
    &:hover,
    &:focus {
        color: #4c372e;
        font-size: 14px;
        border: none;
        border-radius: 0;
        line-height: 1.42857143;
        text-align: center;
        text-decoration: none;
        border-bottom: #4c372e dotted 1px;
        display: inline-block;
    }

    &.active {
        background: #3f7f72;
        border-bottom: #3f7f72 dotted 1px;
        border-radius: 15px;
        color: #fff;
        padding: 5px 25px;
        margin: 0;
        transition: all ease-out 0.2s;
    }
}

.tabs__tabs__nav-link {
    margin: 5px 25px;
}

.tabs-content {
    display: none;

    &.active,
    /* compatibility with bootstrap 3 */
    &.in
    /* /compatibility with bootstrap 3 */
    {
        display: block;
    }

    &.fade {
        transition: opacity .15s linear;

        &.show,
        /* compatibility with bootstrap 3 */
        &.in
        /* /compatibility with bootstrap 3 */
        {
            opacity: 1;
        }

        /* /compatibility with bootstrap 3 */
        &:not(.show):not(.in)
        /* /compatibility with bootstrap 3 */
        {
            opacity: 0;
        }
    }
}

.block-header {
    font-family: "LoraBold", serif;
    color: #4c372e;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: unset;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 20px;
        line-height: 28px;
    }
}

.products-sliders-controls__block-header {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
        text-align: unset;
        margin-bottom: 0;
    }
}

.video-item-link {
    display: flex;
    position: relative;
}

.video-item-link__icon {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    &::before {
        content: "";
        width: 40px;
        height: 40px;
        background: url("./../svg/icon-video-play.svg") center no-repeat;
    }
}

.scroll-top-btn {
    display: flex;
    opacity: 0;
    align-items: center;
    justify-content: center;
    bottom: 5.6rem;
    right: 1.2rem;
    height: 4.5rem;
    width: 4.5rem;
    background: #cd3b3f;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    position: fixed;
    z-index: 10;
    &::before {
        content: "";
        width: 40px;
        height: 40px;
        background: url("./../svg/icon-video-play.svg") center no-repeat;
    }
}

.d-block {
    opacity: 1;
}

.product-card__accordion {
    border-bottom: none;
    margin-top: 15px;
}

.accordion-item {
    margin: 0;
    float: left;
    position: relative;
    display: block;

    &.active {

        .accordion-item__link {
            color: #ffffff;
            border-radius: 15px;
            background-color: #3f7f72;
            padding: 5px 25px;
            margin: 0;
            border: none;
            border-bottom: #3f7f72 dotted 1px;
        }
    }
}

.accordion-item__link {
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 5px 25px;
    line-height: 1.42857143;
    text-align: center;
    text-decoration: none;
    border-bottom: #4c372e dotted 1px;
    display: inline-block;
    background-color: transparent;
    position: relative;
    transition: all ease-out 0.2s;
}

@import "./icons";
@import "./forms";
@import "./catalog";
@import "./product-card";
@import "./checkout";
@import "./sets";
@import "./personal";
