/* @import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins"; */
@import "./_functions";
@import "./_variables";
@import "./_mixins";

.product-card-slider-container {
    display: block;
    position: relative;
    text-align: center;
    background: #fff;
    box-shadow: 0px 17px 18px 0 rgba(0, 0, 0, 0.05);
}

.product-card-slider-navigation {
    @extend .product-slider-navigation;
    position: absolute;
    bottom: 0;
    z-index: 100;
    height: 45px;
    width: 100%;
    border-top: none;
    justify-content: center;

    .product-card-slider-navigation__btn {
        @extend .product-slider-navigation__btn;
    }
    
    .product-card-slider-navigation__btn_prev {
        @extend .product-slider-navigation__btn_prev;

        &:hover {
            background-color: #3f7f72;
            opacity: 0.3;
        }
    }
    
    .product-card-slider-navigation__btn_next {
        @extend .product-slider-navigation__btn_next;

        &:hover {
            background-color: #3f7f72;
            opacity: 0.3;
        }
    }
}

.product-card-slider-pagination {
    line-height: 45px;
    margin: 0 40px;

    &.swiper-pagination-bullets {
        width: initial;
    }

    .swiper-pagination-bullet {
        border: 2px solid #3f7f72;
        transition: background 0.2s ease-out;

        &:hover {
            background: #3f7f72;
        }
    }
}

.product-card-icon {
    position: absolute;
    width: 70px;
    height: 70px;

    &_hit {
        background: url("./../svg/icon-hit.svg") no-repeat;
        top: 0;
        right: 0;
    }

    &_new {
        background: url("./../svg/icon-new.svg") no-repeat;
        top: 0;
        left: 0;
    }
}


.product-card-slider-container__product-badge-action {
    position: absolute;
    bottom: 7px;
    left: 7px;
    z-index: 50;
}

.product-card-slider-container__product-badge-discount {
    position: absolute;
    bottom: 7px;
    right: 7px;
    z-index: 50;
}

.product-card-slider-container__product-badge-hit {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 50;
}

.product-card-slider-container__product-badge-new {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
}

.free-delivery-wrap__free-delivery-icon {
    margin: 0 auto;
}

.card-wrap {
    display: flex;
    flex-wrap: wrap;
}

.card-offers-wrap {
    flex-basis: 100%;
}

.card-left {
    float: none;
    width: inherit;
    margin: 0 0 15px 0;
    max-width: 453px;

    @include media-breakpoint-up(lg) {
        float: left;
        width: 310px;
        margin-right: 20px;
        margin-bottom: 15px;
    }

    @include media-breakpoint-up(xl) {
        float: left;
        width: 453px;
        margin-right: 45px;
        margin-bottom: 15px;
        order: initial;
    }
}

.card-right {
    float: none;
    width: inherit;
    margin: 0;
    order: 1;
    
    .card-brand-wrap,
    .card-sex-wrap {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        float: left;
        width: 260px;
        margin-bottom: 15px;
        order: initial;

        .card-brand-wrap {
            display: block;
        }
    }

    @include media-breakpoint-up(xl) {
        width: 355px;
    }

    @include media-breakpoint-up(xxl) {
        width: 452px;
    }
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}
