.personal-orders-help {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.personal-orders-help-item {
    display: flex;
    align-items: center;
}

.order-complete-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-complete-print {
    display: none;

    @include media-breakpoint-up(sm) {
        display: block;
    }
}

.order-complete-help__order-complete-help-links {
    margin-bottom: 10px;
}
