@import "./../../../scss/functions";
@import "./../../../scss/variables";
@import "./../../../scss/mixins";

.bask-table {

    margin: 0 -15px 15px -15px;
    background: #fefefd;
    box-shadow: 0px 17px 18px 0 rgba(0, 0, 0, 0.05);

    @include media-breakpoint-up(md) {
        margin: 0 0 30px;
    }

    .bs-head {
        background: #f3ede4;
        color: #423a35;

        font-size: 13px;
        line-height: 30px;
        padding: 0 5px;

        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 57px;
            padding: 0 30px;
        }
    }

    .bs-name2 {
        float: left;
    }

    .bs-sum {
        font-size: 12px;
        line-height: 30px;
        width: 100px;
        float: right;
        text-align: left;

        @include media-breakpoint-up(xs370) {
            width: 90px;
        }

        @include media-breakpoint-up(lg) {
            width: 120px;
        }
    }

    .bs-count {
        float: right;
        width: 100px;
        @include media-breakpoint-up(md) {
            width: 226px;
        }
    }
}

.basket-product-decrease-icon {
    display: inline-block;
    cursor: pointer;
    background: url("./../../../svg/icon-basket-product-decrease.svg") no-repeat;
    width: 27px;
    height: 27px;
    text-indent: 100%;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
}

.basket-product-increase-icon {
    display: inline-block;
    cursor: pointer;
    background: url("./../../../svg/icon-basket-product-increase.svg") no-repeat;
    width: 27px;
    height: 27px;
    text-indent: 100%;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
}

.basket-product-delete-icon {
    display: inline-block;
    cursor: pointer;
    background: url("./../../../svg/icon-basket-product-delete.svg") no-repeat;
    width: 36px;
    height: 36px;
    text-indent: 100%;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
}