@charset "utf-8";
/* @import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins"; */
@import "./_functions";
@import "./_variables";
@import "./_mixins";

.products-container {
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 0 20px 0;
    text-align: center;

    @include media-breakpoint-up(xs480) {
        grid-template-columns: 1fr 1fr;
        margin: 0 -5px 20px -5px;
    }

    @include media-breakpoint-up(md) {
        background-color: #fff;
        box-shadow: 0px 17px 18px 0 rgb(0 0 0 / 5%);
        grid-template-columns: 1fr 1fr 1fr;
        margin: 0 0 20px 0;
    }

    &__product-card {
        width: 100%;
        margin: 0 0 10px 0;
        min-height: inherit;

        @include media-breakpoint-up(xs480) {
            width: 230px;
            border: none;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            margin: 0 5px 12px 5px;
        }

        @include media-breakpoint-up(md) {
            min-height: 490px;
            border-right: #dddddd solid 1px;
        }

        @include media-breakpoint-up(lg) {
            float: left;
            margin: 0;
            border-radius: 0;
            position: relative;
        }

        @include media-breakpoint-up(xxl) {
            width: 316px;
        }
    }
}

.mobile-menu-wrap {

    .catalog-menu {
        margin-bottom: 0;
    }
}

.catalog-menu {
    margin-bottom: 25px;
    font-family: 'LoraBold';

    .catalog-menu-item {
        margin: 0 0 12px 0;
        padding: 0 0 10px 0;
        border-bottom: #d9d2cb solid 1px;

        @include media-breakpoint-up(md) {
            margin: 0 0 18px 0;
            padding: 0 0 16px 0;
        }

        &:last-child {
            margin: 0;
            padding: 0;
            border: none;
        }
    }

    .catalog-menu-item__link {
        display: block;
        color: #236c5d;
        font-size: 15px;
        line-height: 22px;
        padding: 0 0 0 28px;
        text-decoration: none;
        position: relative;
        text-transform: uppercase;

        @include media-breakpoint-up(xxl) {
            font-size: 16px;
            line-height: 24px;
            padding: 0 0 0 35px;
        }

        &:hover,
        &:focus {
            color: #cd3b3f;
            background-color: transparent;
            text-decoration: underline;
        }

        &::before {
            content: '';
            display: block;
            background: url("./../svg/icon-star.svg") no-repeat;
            width: 16px;
            height: 16px;
        }
    }

    .catalog-menu-item__link_active {

        &,
        &:hover,
        &:focus {
            color: #cd3b3f;
            background-color: transparent;
            text-decoration: none;
        }
    }

    .catalog-menu__catalog-menu-item {

        &::before {
            position: absolute;
            top: calc(100% - 20px);
            left: 0;
        }
    }
}

.product-card {
    &__border {
        border: #fff solid 4px;
        padding: 0 0 20px 0;

        @include media-breakpoint-up(md) {
            &:hover {
                border-color: #e3d79e;
            }
        }
    }

    &-link {

        &,
        &:hover,
        &:focus {
            display: block;
            text-decoration: none;
            color: #4c372e;
        }
    }

    &__brand {
        font-family: "Roboto-Light", sans-serif;
        height: 22px;
        overflow: hidden;
        margin-bottom: 5px;
    }

    &__name {
        color: #342c26;
        height: 48px;
        font-family: "Roboto-Medium", sans-serif;
        font-size: 16px;
        line-height: 24px;
        overflow: hidden;
        margin: 0 0 5px 0;

        :hover > & {
            color: #4c372e;
            text-decoration: underline;
        }
    }

    &__image {
        display: block;
        height: 130px;
        text-align: center;
    }

    &__fast-view {
        background: url("./../images/fast-view-bg.png");
        box-sizing: border-box;
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-size: 13px;
        display: block;
        bottom: 130px;
        position: absolute;
        right: 10px;
        border-radius: 50%;
        margin: 0;
        width: 80px;
        height: 80px;
        line-height: 18px;
        padding: 20px 0 0 0;
        text-decoration: none;
        text-align: center;

        @include media-breakpoint-up(lg) {
            margin: 0 auto 0 -80px;
            bottom: 110px;
            left: 50%;
            border-radius: 30px;
            line-height: 30px;
            width: 160px;
            height: inherit;
            display: none;
            padding: inherit;

            :hover > & {
                display: block;
            }
        }
    }

    &__price {
        font-family: "Roboto-Medium", sans-serif;
        color: #cd3b3f;

        &_size_m {
            font-size: 18px;
            height: 27px;
            line-height: 26px;
            margin: 5px 0 29px 0;
        }

        &_size_s {
            font-size: 16px;
            line-height: 24px;
            margin: 20px 0 15px 0;
        }
    }

    &__btn-buy {
        font-family: "Roboto-Light", sans-serif;
        display: inline-block;
        width: 120px;
        border-radius: 15px;
        height: 30px;
        margin: 0 auto;
        background: #f3f3f3;
        color: #4c372e;
        font-size: 14px;
        line-height: 30px;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
            color: #fff;
            background: #cd3b3f;
        }
    }

    &__icon {
        position: absolute;
        width: 70px;
        height: 70px;

        &_hit {
            background: url("./../svg/icon-hit.svg") no-repeat;
            top: 0;
            right: 0;
        }

        &_new {
            background: url("./../svg/icon-new.svg") no-repeat;
            top: 0;
            left: 0;
        }
    }

    &__action {
        position: absolute;
        bottom: 7px;
        left: 7px;
        width: 5px;
        height: 5px;
        background: #2fbf22;
        border-radius: 50%;
    }
}


/*  */
.product-badge-discount {
    width: 55px;
    height: 55px;
    background: #f0555b;
    border-radius: 50%;
    font-family: 'Roboto-Bold';
    font-size: 20px;
    line-height: 55px;
    text-align: center;
    color: #fff;
    font-style: normal;
}

.product-badge-action {
    width: 5px;
    height: 5px;
    background: #2fbf22;
    border-radius: 50%;
}

.product-badge-hit {
    background: url("./../svg/icon-hit.svg") no-repeat;
    width: 70px;
    height: 70px;
}

.product-badge-new {
    background: url("./../svg/icon-new.svg") no-repeat;
    width: 70px;
    height: 70px;
}
