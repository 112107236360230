.social {
    color: #0b0b0b;
    font-size: 16px;
    line-height: 22px;
    margin: 50px 0 20px 0;

    &__text {
        margin-bottom: 15px;
    }

    &__link {
        display: inline-block;
        width: 33px;
        height: 33px;
        margin: 0 3px;
        text-indent: -90000%;

        &_facebook {
            background: url("./../svg/icon-social-facebook.svg") no-repeat;
        }

        &_instagram {
            background: url("./../svg/icon-social-instagram.svg") no-repeat;
        }

        &_ok {
            background: url("./../svg/icon-social-ok.svg") no-repeat;
        }

        &_telegram {
            background: url("./../svg/icon-social-telegram.svg") no-repeat;
        }

        &_twitter {
            background: url("./../svg/icon-social-twitter.svg") no-repeat;
        }

        &_vk {
            background: url("./../svg/icon-social-vk.svg") no-repeat;
        }

        &_whatsapp {
            background: url("./../svg/icon-social-whatsapp.svg") no-repeat;
        }

        &_youtube {
            background: url("./../svg/icon-social-youtube.svg") no-repeat;
        }

        &_viber {
            background: url("./../svg/icon-social-viber.svg") no-repeat;
        }
    }
}

.product-icon_hit {
    background: url("./../svg/icon-hit.svg") no-repeat;
}

.product-icon_new {
    background: url("./../svg/icon-new.svg") no-repeat;
}

.free-delivery-icon {
    background: url("./../svg/icon-free-delivery.svg") no-repeat;
    width: 31px;
    height: 24px;
    text-indent: -90000%;
}

.basket-product-decrease-icon {
    display: inline-block;
    cursor: pointer;
    background: url("./../svg/icon-basket-product-decrease.svg") no-repeat;
    width: 27px;
    height: 27px;
    text-indent: 100%;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
}

.basket-product-increase-icon {
    display: inline-block;
    cursor: pointer;
    background: url("./../svg/icon-basket-product-increase.svg") no-repeat;
    width: 27px;
    height: 27px;
    text-indent: 100%;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
}

.basket-product-delete-icon {
    display: inline-block;
    cursor: pointer;
    background: url("./../svg/icon-basket-product-delete.svg") no-repeat;
    width: 36px;
    height: 36px;
    text-indent: 100%;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
}

.search-icon-white {
    align-items: center;
    display: flex;
    background-color: #cd3b3f;
    cursor: pointer;
    width: 60px;
    height: 60px;
    justify-content: center;
    // text-indent: 100%;
    // vertical-align: middle;
    // white-space: nowrap;
    // overflow: hidden;

    &::after {
        content: "";
        width: 20px;
        height: 21px;
        background: url("./../svg/icon-search-white.svg") no-repeat;
    }
}

.printer-icon-grey {
    background: url("./../svg/icon-printer-grey.svg") no-repeat;
    display: inline-block;
    width: 20px;
    height: 18px;
    text-indent: 100%;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
}

.icon-catalog-mobile {
    display: block;
    width: 25px;
    height: 25px;
    background: url("./../svg/icon-catalog-mobile.svg") no-repeat;
}

.icon-brands-mobile {
    display: block;
    width: 25px;
    height: 25px;
    background: url("./../svg/icon-brands-mobile.svg") no-repeat;
}

.icon-search-mobile {
    display: block;
    width: 45px;
    height: 45px;
    background: url("./../svg/icon-search-mobile.svg") no-repeat;
}

.icon-basket-mobile {
    display: block;
    width: 45px;
    height: 45px;
    background: url("./../svg/icon-basket-mobile.svg") no-repeat;
}

.phone-icon-green {
    display: block;
    width: 45px;
    height: 45px;
    background: url("./../svg/icon-phone-green.svg") no-repeat;
}

.video-play-icon {
    width: 45px;
    height: 45px;
    background: url("./../svg/icon-video-play.svg") no-repeat;

    &:hover {
        background: url("./../svg/icon-video-play-hover.svg") no-repeat;
    }
}
